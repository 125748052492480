var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "background-color": "#ffffff", height: "100%" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("PREPARE")
          },
          "head-submit": function ($event) {
            return _vm.headSave("FINISHED")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        { staticClass: "formContentBox" },
        [
          _c(
            "div",
            { staticClass: "formMain" },
            [
              _c(
                "avue-form",
                {
                  ref: "addForm",
                  attrs: { option: _vm.formOptions },
                  model: {
                    value: _vm.addForm,
                    callback: function ($$v) {
                      _vm.addForm = $$v
                    },
                    expression: "addForm",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "complainCode" },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "请输入记录编号",
                            disabled:
                              _vm.isAutomatic ||
                              _vm.pageDisabled ||
                              _vm.addForm.id ||
                              _vm.formId,
                          },
                          model: {
                            value: _vm.addForm.complainCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "complainCode", $$v)
                            },
                            expression: "addForm.complainCode",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "append" }, slot: "append" },
                            [
                              _vm._v(
                                "\n              自动生成\n              "
                              ),
                              _c("el-switch", {
                                attrs: {
                                  disabled:
                                    _vm.pageDisabled ||
                                    _vm.addForm.id ||
                                    _vm.formId,
                                  "active-color": "#13ce66",
                                },
                                on: { change: _vm.handleSwitch },
                                model: {
                                  value: _vm.isAutomatic,
                                  callback: function ($$v) {
                                    _vm.isAutomatic = $$v
                                  },
                                  expression: "isAutomatic",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "userName" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入投诉人",
                            disabled: _vm.pageDisabled || _vm.checked,
                          },
                          model: {
                            value: _vm.addForm.userName,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "userName", $$v)
                            },
                            expression: "addForm.userName",
                          },
                        }),
                        _c(
                          "el-checkbox",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              disabled:
                                _vm.pageDisabled ||
                                _vm.addForm.id ||
                                _vm.formId,
                            },
                            model: {
                              value: _vm.checked,
                              callback: function ($$v) {
                                _vm.checked = $$v
                              },
                              expression: "checked",
                            },
                          },
                          [_vm._v("匿名")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "template",
                    { slot: "files" },
                    [
                      _c(
                        "el-upload",
                        {
                          class: { uploadhideAdd: _vm.pageDisabled },
                          attrs: {
                            disabled: _vm.pageDisabled,
                            action:
                              "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                            "list-type": "picture-card",
                            accept: ".jpg,.png,.mp4",
                            limit: 9,
                            placeholder: "请上传图片视频",
                            "file-list": _vm.fileList,
                            headers: _vm.headers,
                            "on-success": _vm.uploadSuccess,
                            "on-remove": _vm.handleRemove,
                            "on-preview": _vm.handlePreview,
                            "on-exceed": _vm.uploadExceed,
                            "on-change": _vm.uploadChange,
                            "before-upload": _vm.uploadBefore,
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                "\n              图片支持*.jpg/*.png格式,文件大小不超过5M;视频支持*MP4格式,文件大小不超过20M\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm.dialogVisible
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: "预览",
                    visible: _vm.dialogVisible,
                    "append-to-body": "",
                    top: "5vh",
                    "custom-class": "customDialog",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _vm.uploadType == "img"
                    ? _c("img", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "object-fit": "contain",
                        },
                        attrs: { src: _vm.dialogImageUrl, alt: "" },
                      })
                    : _vm._e(),
                  _vm.uploadType == "video"
                    ? _c("video", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "object-fit": "contain",
                        },
                        attrs: {
                          autoplay: "",
                          src: _vm.dialogVideoUrl,
                          controls: "controls",
                        },
                      })
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }